import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import { renderAuthorsName } from "../js/util";
import { renderThoughtCats } from "../js/util";
import { renderPostDate } from "../js/util";
import { renderAuthorsImg } from "../js/util";

import Layout from "../components/global/Layout";
import SEO from "../components/global/SEO";

import "../components/sass/templates/_thought-piece.scss";

const ThoughtPieceTemplate = ({ data }) => {

  const post_title = data.currentThoughtPiece.post_title;
  const post_content = data.currentThoughtPiece.content;
  const gated = data.currentThoughtPiece.acf.gated;

  if(gated) {
    const gate_password = data.currentThoughtPiece.acf.gate_password;
    const gate_image = data.currentThoughtPiece.acf.gate_image;

    const tryAgain = [
      "Sorry. Try again.",
      "lol. Nope.",
      "You did it! J.K. No you didn’t.",
      "That the best you can do??",
      "Disappointing.",
      "You can do better than that.",
      "Oh come on... this is the easiest one!",
      "Sorry. No participation trophies here."
    ]

    let skipAvailable = false;



    //
    // Handle the knock at the gate
    //
    const handleGateKnock = function(e) {
      // Prevent the form submission
      e.preventDefault();

      // Show a loading image
      document.querySelector(".js-tank-gate-loading").classList.add("is-loading");

      // Get the password
      const user_password = e.target[0].value;
      let desired_password = user_password.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();

      // GA
      if (typeof ga === 'function') {
        ga('send', 'event', 'Thought - ' + post_title, 'Submit', user_password);
      }

      // Check the password
      if( desired_password === gate_password ) {
        // Password was correct! Load the content
        openGate();
      } else {
        // Password was incorrect. Show a try again message.
        const tryMessage = tryAgain[ Math.floor(Math.random() * tryAgain.length) ];
        document.querySelector(".js-tank-gate-title").classList.add("is-wrong");
        document.querySelector(".js-tank-gate-title span").innerHTML = tryMessage;
        document.querySelector(".js-tank-gate-loading").classList.remove("is-loading");

        // Also allow skipping
        skipAvailable = true;
        document.querySelector(".js-tank-gate-skip").style.display = "block";
      }
    }



    //
    // Handle a barge through the gate
    //
    const handleGateBarge = function(e) {
      e.preventDefault();

      // Only if skipping enabled
      if(skipAvailable) {
        // Show a loading image
        document.querySelector(".js-tank-gate-loading").classList.add("is-loading");

        // GA
        if (typeof ga === 'function') {
          ga('send', 'event', 'Thought - ' + post_title, 'Skipped', 'true');
        }

        openGate();
      }
    }



    //
    // Show the gated content
    //
    function openGate() {

      // Show the success message
      document.querySelector(".js-tank-gate-success").style.display = "block";

      // Add the markup to the page and show it
      document.querySelector(".js-tank-gate-content").innerHTML = post_content;
      document.querySelector(".js-tank-gate-content").style.display = "block";
      
      // GA
      if (typeof ga === 'function') {
        ga('send', 'event', 'Thought - ' + post_title, 'Article Shown', 'true');
      }

      // Remove loader
      document.querySelector(".js-tank-gate-loading").classList.remove("is-loading");
    }




    return (
      <Layout>
        <SEO yoast={data.currentThoughtPiece.yoast} />
        <div className="tank-gate l-content">
          <div className="tank-gate__img">
            <Img alt={ `Gate image for ${data.currentThoughtPiece.post_title}` } fluid={gate_image.localFile.childImageSharp.fluid} />
          </div>
          <h1 className="tank-gate__title js-tank-gate-title"><span>Got it figured out?</span></h1>
          <form className="tank-gate__form js-tank-gate" action="" method="POST" onSubmit={handleGateKnock}>
            <label htmlFor="tank-gate-password" className="screen-reader-text">Enter your guess here.</label>
            <input id="tank-gate-password" className="tank-gate__input" type="text" placeholder="Enter your guess here." />
            <div className="tank-gate__btn-row">
              <button className="tank-gate__submit">Submit</button>
              <div className="tank-gate__loading js-tank-gate-loading">
                <svg width="41" height="41" viewBox="0 0 41 41" xmlns="http://www.w3.org/2000/svg" stroke="#000"><g fill="none" fillRule="evenodd"><g transform="translate(3 3)" strokeWidth="3"><circle strokeOpacity=".5" cx="18" cy="18" r="18"/><path d="M36 18c0-9.94-8.06-18-18-18"> <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite"/></path></g></g></svg>
              </div>
            </div>
            <div className="tank-gate__skip js-tank-gate-skip">
              Can't hack it? <a href="#" className="tank-gate__skip-link js-tank-gate-skip-link" onClick={handleGateBarge}>Take the easy road</a>
            </div>
          </form>
        </div>

        <div className="tank-gate-success t-black l-content js-tank-gate-success">
          <h2>You got it!</h2>
        </div>

        <div className="tank-gate-content t-copy t-black l-content l-content--fixed js-tank-gate-content"></div>
      </Layout>
    )
  } else {
    return (
      <Layout>
        <SEO yoast={data.currentThoughtPiece.yoast} />
        <div
          className={`page-content-header`}
        >
          {/* <div className="page-content-header__content l-content l-content--fixed">
            <div className="page-content-header-col page-content-header-col--left l-column--left">
              <div className="page-content-header__small-title">
                What's New
              </div>
            </div>
            <div className="page-content-header-col page-content-header-col--right l-column--right">
              <div
                className="page-content-header__title t-copy"><h1>{post_title}</h1>
              </div>
              
            </div>
          </div> */}
          <div className="thought-piece-single-header__img l-content l-content--fixed">
            <Img alt={`Hero image for ${data.currentThoughtPiece.post_title}`} fluid={data.currentThoughtPiece.image.localFile.childImageSharp.fluid} />
          </div>
        </div>
        <div
          className="thoughts-archive-featured l-content l-content--fixed l-col-container"
          key={data.currentThoughtPiece.post_title}
        >
          {/* <div className="thoughts-archive__col thoughts-archive__col--left l-column--left">
            <div className="thoughts-archive__cat t-h6">
              {renderThoughtCats(data.currentThoughtPiece.thought_categories)}
            </div>
            <p className="thoughts-archive__date">
              Posted: {renderPostDate(data.currentThoughtPiece.post_date)}
            </p>
            <p className="thoughts-archive__authors">
              {renderAuthorsName(
                data.currentThoughtPiece.acf.thought_piece_blocks[0].block_type[0].authors
              )}
            </p>
          </div> */}
          {/* <div className="thoughts-archive__col thoughts-archive__col--right l-column--right">
            
            <div className="thoughts-archive__headshots single">
              {renderAuthorsImg(
                data.currentThoughtPiece.acf.thought_piece_blocks[0].block_type[0].authors
              )}
            </div>
          </div> */}
        </div>
        <div className="t-copy t-black l-content l-content--fixed" dangerouslySetInnerHTML={{ __html: post_content }}></div> 
      </Layout>
    )
  }
};

export default ThoughtPieceTemplate;





export const thoughtPieceQuery = graphql`
  query($id: String!) {
    currentThoughtPiece: wordpressThoughtPieceEndpointThoughtPieces(id: { eq: $id }) {
      post_title
      yoast {
        focuskw
        title
        metadesc
        opengraph_title
        opengraph_description
        opengraph_updated_time
        twitter_title
        twitter_description
        linkdex
        metakeywords
        meta_robots_noindex
        meta_robots_nofollow
        meta_robots_adv
        canonical
        redirect
        opengraph_url
        opengraph_site_name
        opengraph_type
      }
      thought_categories {
        name
      }
      post_date
      post_title
      content
      image {
        localFile {
          childImageSharp {
            fluid(quality: 100, maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      acf {
        gated
        gate_password
        gate_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
       thought_piece_blocks {
          block_type {
            acf_fc_layout
            authors {
              a_company
              a_name
              a_title
              a_headshot {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 1000) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
